'use client';

import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import Button from '@mui/material/Button';
import { StoreLocator as StoreLocatorInput, useGtmEvent } from 'ui/components';
import type { EventArguments } from 'ui/components';
import { PortableText } from '@portabletext/react';
import { useTranslation } from '@lib/i18n';
import type { TranslationsDictionary } from '@lib/i18n';
import type { StoreLocatorDocument } from '@lib/sanity/types';
import Coupon from './coupon';
import Section from './section';

type Props = {
  data?: StoreLocatorDocument;
  buyNowTargetUrl?: string;
  locale: string;
  translationsDictionary: TranslationsDictionary;
};

const BUY_NOW_TARGET_URL = 'https://www.naturitas.es/b/emser';
const purchaseEvent: EventArguments = {
  event: 'purchase',
  transaction_id: Date.now().toString(),
  currency: 'EUR',
  value: 1,
};
const storeLocatorSearchEvent: EventArguments = {
  event: 'store-locator-search',
  transaction_id: Date.now().toString(),
  currency: 'EUR',
  value: 1,
};

function StoreLocatorInputs({ data, buyNowTargetUrl, locale, translationsDictionary }: Props): JSX.Element | null {
  const t = useTranslation(translationsDictionary);
  const pushEvent = useGtmEvent();

  function handleCtaClick(): void {
    pushEvent(purchaseEvent);
  }

  function handleOnStoreSearch(): void {
    pushEvent(storeLocatorSearchEvent);
  }

  if (!data) {
    return null;
  }

  const couponData = {
    couponCode: data.couponCode,
    couponCopyLabel: data.couponCopyLabel,
    couponTitle: data.couponTitle,
  };

  return (
    <div className="flex gap-x-8 flex-col justify-center items-center w-full max-w-screen-md">
      {data.headline ? (
        <h3 className="w-full pb-6 md:pb-10 lg:pb-12 text-2xl lg:text-4xl text-primary font-bold leading-7 lg:leading-10 text-center">
          {data.headline}
        </h3>
      ) : null}
      <Coupon className="hidden sm:flex mb-5" data={couponData} translationsDictionary={translationsDictionary} />

      <div
        className={`flex flex-col sm:flex-row w-full  ${
          data.variant === 'compact'
            ? 'items-end gap-x-2 xl:gap-x-6 gap-y-4'
            : 'justify-center items-center gap-x-6 gap-y-4'
        }`}
      >
        {data.variant === 'compact' ? (
          <div className="w-full text-center text-primary 'text-base sm:hidden">
            {data.ctaTitle ? (
              <PortableText value={data.ctaTitle} />
            ) : (
              <>
                {t('storeLocator.buyOnline')} <strong>{t('storeLocator.freeShipping')}</strong>
              </>
            )}
          </div>
        ) : null}
        <div
          className={`flex flex-col  rounded-lg bg-white ${
            data.variant === 'compact' ? 'sm:bg-transparent px-3 sm:px-0 py-2 sm:py-0' : 'px-3 py-4'
          } ${data.showStoreLocator ? 'w-full' : 'w-2/3'} `}
        >
          <div
            className={`w-full text-center text-primary ${
              data.variant === 'compact' ? 'hidden sm:block text-base sm:text-left' : 'text-lg'
            }`}
          >
            {data.ctaTitle ? (
              <PortableText value={data.ctaTitle} />
            ) : (
              <>
                {t('storeLocator.buyOnline')} <strong>{t('storeLocator.freeShipping')}</strong>
              </>
            )}
          </div>

          <Coupon className="sm:hidden" data={couponData} translationsDictionary={translationsDictionary} />
          <Button
            className={`whitespace-nowrap ${data.variant === 'compact' ? 'mt-2 py-2 text-lg' : 'mt-4 py-2.5 text-xl'}`}
            color="primary"
            disableElevation
            href={buyNowTargetUrl ? buyNowTargetUrl : BUY_NOW_TARGET_URL}
            onClick={handleCtaClick}
            startIcon={<ShoppingCartOutlinedIcon className="w-6 h-6" />}
            variant="contained"
          >
            {data.ctaLabel ? data.ctaLabel : t('storeLocator.buyNow')}
          </Button>
          {data.couponDisclaimer ? (
            <div className="sm:hidden w-full pt-3 text-center text-xs text-primary">{data.couponDisclaimer}</div>
          ) : null}
        </div>
        <div
          className={`flex flex-col w-full rounded-lg ${data.variant === 'compact' ? '' : 'px-3 py-4 bg-white'} ${
            data.showStoreLocator ? 'visible' : 'hidden'
          }`}
        >
          <div
            className={`w-full text-center text-primary ${
              data.variant === 'compact' ? 'text-base sm:text-left' : 'text-lg'
            }`}
          >
            {data.ctaInputTitle ? (
              <PortableText value={data.ctaInputTitle} />
            ) : (
              <>
                {t('storeLocator.findUs')} <strong>{t('storeLocator.closestPharmacy')}</strong>
              </>
            )}
          </div>

          <div className={data.variant === 'compact' ? 'px-2 sm:px-0' : ''}>
            <StoreLocatorInput
              brand="emser"
              countryCode={locale}
              inputAriaLabel={t('storeLocator.inputAriaLabel')}
              noLocationsFoundMsg={t('storeLocator.noLocationsFoundMsg')}
              noOptionsText={t('storeLocator.noOptionsText')}
              onStoreSearch={handleOnStoreSearch}
              placeholder={t('storeLocator.placeholder')}
              searchButtonAriaLabel={t('storeLocator.searchButtonAriaLabel')}
              title={t('storeLocator.modalTitle')}
              useMyLocationText={t('storeLocator.useMyLocationText')}
              variant={data.variant}
            />
          </div>
        </div>
      </div>
      {data.couponDisclaimer ? (
        <div className="hidden sm:block w-full p-3 text-center text-xs text-primary">{data.couponDisclaimer}</div>
      ) : null}
    </div>
  );
}

export default function StoreLocator({ data, buyNowTargetUrl, locale, translationsDictionary }: Props): JSX.Element {
  if (data?.variant === 'compact') {
    return (
      <StoreLocatorInputs
        buyNowTargetUrl={buyNowTargetUrl}
        data={data}
        locale={locale}
        translationsDictionary={translationsDictionary}
      />
    );
  }

  return (
    <Section bgColor="bg-accent-5">
      <StoreLocatorInputs
        buyNowTargetUrl={buyNowTargetUrl}
        data={data}
        locale={locale}
        translationsDictionary={translationsDictionary}
      />
    </Section>
  );
}
