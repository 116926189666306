import type { Locale, Rewrite } from './types';

/**
 * Array of sites/languages that are supported.
 * A site can be a domain or a directory (e.g. /de/ or emser.de)
 */
export const locales: Locale[] = [
  { id: 'es-ES', title: 'Spanish (Spain)', path: 'es', isDefault: true },
  { id: 'en-GB', title: 'English (UK)', path: 'gb' },
  { id: 'de-DE', title: 'German (Germany)', path: 'de' },
  { id: 'pt-PT', title: 'Portuguese (Portugal)', path: 'pt' },
];

/**
 * Array of rewrites to be applied in the middleware
 */
export const rewrites: Rewrite[] = [
  { source: '/productos/', destination: '/es/products/' },
  { source: '/es/productos/', destination: '/es/products/' },
  { source: '/de/produkte/', destination: '/de/products/' },
  { source: '/pt/produtos/', destination: '/pt/products/' },
];
