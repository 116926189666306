'use client';

import Link from 'next/link';
import { useHoverIntent } from 'ui/hooks';

type MenuItemProps = {
  item: {
    label: string;
    href: string;
    dropdown?: (isVisible: boolean) => JSX.Element;
  };
};

export default function MenuItem({ item }: MenuItemProps): JSX.Element {
  const [isNavItemHovered, intentRef, setIsNavItemHovered] = useHoverIntent({ sensitivity: 100 });

  return (
    <li
      className="lg:pt-[0.375rem]"
      key={item.label}
      onBlur={() => {
        setIsNavItemHovered(false);
      }}
      onFocus={() => {
        setIsNavItemHovered(true);
      }}
      ref={intentRef}
    >
      <Link
        className={`relative flex items-center h-full lg:px-6 hover:no-underline focus:no-underline focus:outline-0 ${
          isNavItemHovered
            ? `text-white md:after:content-[""] after:absolute after:bottom-0 after:left-0 after:w-full after:h-1 after:bg-white`
            : 'text-inherit'
        }`}
        href={item.href}
      >
        {item.label}
      </Link>
      {item.dropdown ? item.dropdown(isNavItemHovered) : null}
    </li>
  );
}
