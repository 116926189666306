'use client';

import { useState } from 'react';
import type { Dispatch, SetStateAction } from 'react';
import Link from 'next/link';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import type { SelectChangeEvent } from '@mui/material/Select';
import { CorporateFooterButton, CorporateFooterPanel, useCookieConfigModal } from 'ui/components';
import { useTranslation } from '@lib/i18n';
import type { TranslationsDictionary } from '@lib/i18n';
import { FacebookIcon, InstagramIcon } from '@public/icons';

export default function Footer({ dict }: { dict: TranslationsDictionary }): JSX.Element {
  const [isCorporateFooterOpen, setIsCorporateFooterOpen] = useState(false);
  const { setIsConfigOpen } = useCookieConfigModal();
  const t = useTranslation(dict);
  const handleCorporateFooter = (): void => {
    setIsCorporateFooterOpen(!isCorporateFooterOpen);
  };
  const handleChange = (event: SelectChangeEvent): void => {
    if (event.target.value === 'de-DE') {
      window.location.href = 'https://www.emser.de/';

      return;
    }

    window.location.href = event.target.value;
  };

  return (
    <>
      <footer className="flex justify-center w-full overflow-hidden px-4 pt-8 md:px-10 lg:px-16 bg-primary">
        <div className="flex flex-wrap w-full max-w-screen-xl gap-x-24">
          <div className="flex flex-col items-center w-full sm:w-[12rem]">
            <div className="flex justify-center h-14 md:h-16">
              <Link
                aria-label="Página principal de Emser"
                className="flex justify-center grow lg:grow-0 h-full focus-visible:outline-accent-1 focus-visible:outline-2 focus-visible:outline-offset-[-2px] z-10"
                href="/"
              >
                <img alt="Emser" className="w-auto h-full" src="/logos/emser.png" />
              </Link>
            </div>
            <ul className="flex w-full max-w-xs justify-around py-6">
              {socialNetworks().map((socialNetwork) => (
                <li key={socialNetwork.alt}>
                  <a
                    aria-label={socialNetwork.alt}
                    className="flex justify-center items-center w-9 h-9 bg-accent-3 text-primary rounded-full p-2"
                    href={socialNetwork.href}
                    rel="nofollow noreferrer"
                    target="_blank"
                  >
                    <socialNetwork.icon height={20} width={20} />
                  </a>
                </li>
              ))}
            </ul>
            <FormControl className="w-full max-w-xs">
              <Select
                className="bg-accent-3 h-10 text-primary"
                name="site-selector"
                onChange={handleChange}
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                  },
                  '& .MuiSvgIcon-root': {
                    color: 'inherit',
                  },
                }}
                value="es-ES"
              >
                <MenuItem value="es-ES">España</MenuItem>
                <MenuItem value="de-DE">Deutschland</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="flex flex-col sm:flex-row grow gap-x-20 pt-8 sm:pt-0 text-white">
            <ul className="flex flex-col">
              {menuItems()
                .filter((item) => item.column === 1)
                .map((item) => (
                  <li className="text-center sm:text-left" key={item.label}>
                    <Link className="text-inherit md:text-lg leading-10" href={item.href}>
                      {item.label}
                    </Link>
                  </li>
                ))}
            </ul>
            <ul className="flex flex-col">
              {menuItems()
                .filter((item) => item.column === 2)
                .map((item) => (
                  <li className="text-center sm:text-left" key={item.label}>
                    <Link className="text-inherit md:text-lg leading-10" href={item.href}>
                      {item.label}
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
          <div className="flex flex-col flex-wrap md:flex-row lg:flex-nowrap w-full pt-6 text-xs lg:text-sm font-normal text-accent-1">
            <div className="flex flex-col sm:flex-row w-full">
              <div className="flex w-full sm:w-auto py-2 justify-center items-center md:text-left whitespace-nowrap leading-4">
                &copy;&nbsp;{t('footer.copyright')}
              </div>
              <ul className="flex flex-wrap grow sm:grow-0 lg:grow sm:ml-auto justify-center items-center sm:px-4 text-center lg:justify-center gap-x-4">
                {legalLinks(setIsConfigOpen).map((legalLink) => (
                  <li key={legalLink.label}>
                    {legalLink.href ? (
                      <Link
                        className="inline-block text-inherit whitespace-nowrap leading-[48px]"
                        href={legalLink.href}
                      >
                        {legalLink.label}
                      </Link>
                    ) : (
                      <Button
                        className="p-0 text-inherit text-xs lg:text-sm font-normal whitespace-nowrap leading-[48px] normal-case rounded-sm hover:underline focus:underline focus-visible:outline focus-visible:outline-white focus-visible:outline-1 focus-visible:outline-offset-[2px]"
                        disableFocusRipple
                        disableRipple
                        onClick={legalLink.onClick}
                      >
                        {legalLink.label}
                      </Button>
                    )}
                  </li>
                ))}
              </ul>
            </div>
            <div className="flex w-full lg:w-auto justify-center items-end pt-4 lg:pt-0">
              <CorporateFooterButton
                className="bg-white hover:bg-white"
                isOpen={isCorporateFooterOpen}
                onClick={handleCorporateFooter}
                variant="color-logo"
              />
            </div>
          </div>
        </div>
      </footer>
      <CorporateFooterPanel bgColor="bg-white" color="text-primary" isOpen={isCorporateFooterOpen} />
    </>
  );
}

function menuItems(): { label: string; href: string; column: number }[] {
  return [
    {
      label: 'Home',
      href: '/',
      column: 1,
    },
    {
      label: 'Productos',
      href: '/#productos',
      column: 1,
    },
    {
      label: 'FAQs',
      href: '/preguntas-frecuentes',
      column: 1,
    },
    {
      label: 'Dónde Comprar',
      href: '/donde-comprar',
      column: 1,
    },
  ];
}

function legalLinks(
  setIsCookieConfigModalOpen: Dispatch<SetStateAction<boolean>>
): { label: string; href?: string; onClick?: () => void }[] {
  return [
    {
      label: 'Política de Privacidad',
      href: '/politica-de-privacidad',
    },
    {
      label: 'Aviso Legal',
      href: '/aviso-legal',
    },
    {
      label: 'Política de Cookies',
      href: '/politica-de-cookies',
    },
    {
      label: 'Configurar Cookies',
      onClick: () => {
        setIsCookieConfigModalOpen(true);
      },
    },
  ];
}

function socialNetworks(): { alt: string; href: string; icon: React.ComponentType<React.SVGProps<SVGSVGElement>> }[] {
  return [
    {
      alt: 'Síguenos en Instagram',
      href: 'https://www.instagram.com/emser.eu/?hl=es',
      icon: InstagramIcon,
    },
    {
      alt: 'Síguenos en Facebook',
      href: 'https://www.facebook.com/profile.php?id=61553984426785',
      icon: FacebookIcon,
    },
  ];
}
