type SectionProps = {
  bgColor?: string;
  children: React.ReactNode;
};

export default function Section({ bgColor, children }: SectionProps): JSX.Element {
  return (
    <section
      className={`flex flex-col w-full justify-center items-center py-10 sm:py-12 lg:py-20 ${
        bgColor ? bgColor : 'bg-white'
      }`}
    >
      <div className="flex w-full justify-center px-4 md:px-10 lg:px-16">{children}</div>
    </section>
  );
}
