'use client';

import { useCallback, useEffect, useState } from 'react';
import Link from 'next/link';
import IconButton from '@mui/material/IconButton';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
// import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { useWindowSize } from 'ui/hooks';
import { useTranslation } from '@lib/i18n';
import type { TranslationsDictionary } from '@lib/i18n';
import type { Settings } from '@lib/sanity/types';
import MenuItem from './menu-item';

export default function Header({ config, dict }: { config: Settings; dict: TranslationsDictionary }): JSX.Element {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(true);
  const t = useTranslation(dict);
  const { width } = useWindowSize();
  const onEscapeKeyPressed = useCallback((event: KeyboardEvent): void => {
    if (event.key === 'Escape') {
      setIsMenuOpen(false);
    }
  }, []);

  useEffect(() => {
    if (width >= 1200) return;

    // prevents the mobile menu from flickering when resizing the window from desktop to mobile
    setIsMenuVisible(false);

    setTimeout(() => {
      setIsMenuVisible(true);
    }, 0);
  }, [width]);

  useEffect(() => {
    document.addEventListener('keydown', onEscapeKeyPressed, false);

    return () => {
      document.removeEventListener('keydown', onEscapeKeyPressed, false);
    };
  }, [onEscapeKeyPressed]);

  function toggleMenu(): void {
    setIsMenuOpen(!isMenuOpen);

    if (isMenuOpen) {
      document.body.classList.remove('no-scroll');

      return;
    }

    document.body.classList.add('no-scroll');
  }

  return (
    <header className="fixed lg:relative flex w-full justify-center h-14 md:h-16 lg:h-20 px-2 md:px-8 lg:px-16 transition-colors z-20 text-white bg-primary">
      <div className="flex flex-wrap lg:flex-nowrap w-full max-w-screen-xl">
        <IconButton
          aria-label={isMenuOpen ? t('header.closeMenu') : t('header.openMenu')}
          onClick={toggleMenu}
          sx={{
            display: {
              lg: 'none',
            },
            zIndex: 11,
          }}
        >
          <MenuOutlinedIcon className="text-white" />
        </IconButton>
        <Link
          aria-label={t('header.logoLinkAriaLabel')}
          className="flex justify-center grow lg:grow-0 lg:w-[3.8125rem] h-full focus-visible:outline-accent-1 focus-visible:outline-2 focus-visible:outline-offset-[-2px] z-10"
          href="/"
        >
          <img alt="Emser" className="w-auto h-[142%]" src="/logos/emser-full.png" />
        </Link>
        {isMenuVisible ? (
          <nav
            className={`fixed lg:static top-14 md:top-16 lg:top-auto lg:left-auto left-0 right-0 bottom-0 flex grow basis-full lg:basis-auto order-last lg:order-none transition ease-in delay-150 duration-300 lg:transition-none bg-white lg:bg-transparent ${
              isMenuOpen ? 'translate-y-0' : 'translate-y-[calc(-100%-4rem)] lg:transform-none'
            }`}
          >
            <ul className="flex flex-col lg:flex-row w-full px-4 md:px-10 lg:px-0 py-8 lg:py-0 lg:w-auto text-xl lg:text-[1.0625rem] gap-y-3 font-semibold bg-primary">
              {config.mainMenuItems?.map((item) => <MenuItem item={item} key={item.label} />)}
            </ul>
          </nav>
        ) : null}
        <div className="flex justify-end items-center w-10 lg:w-[3.75rem] lg:pt-[0.375rem]">
          {/* TODO: Search functionality will be implemented in the future
          <IconButton aria-label="search" className={`${isMenuOpen ? 'hidden' : ''} text-white`}>
            <SearchOutlinedIcon />
          </IconButton>
          */}
        </div>
      </div>
    </header>
  );
}
